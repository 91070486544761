// @flow

import React from 'react'
import { applyStylingHoc, type StyleOuterProps, type StyleProps } from '@fcse/tsbob/dist/lib/styling'
import ScrollAnimation from 'react-animate-on-scroll'
import HeroTeaser from '../../components/HeroTeaser'
import CaseTeasers from '../../components/CaseStudy/CaseTeasers'
import ProductTeaser from '../ProductTeaser'
import Services from '../../components/Services'
import Solutions from '../../components/Solutions'
import Clients from '../../components/Clients'
import Reviews from '../../components/Reviews'
import Benefits from '../../components/Benefits'
import Products from '../../components/Products'
import Scrollindicator from '../../components/basic/Scrollindicator'
import baseStyles from './HomePage.scss'

type Props = {
  ...StyleProps
}

const HomePage = ({ styles }: Props) => {
  return (
    <div className={styles.root}>
      <HeroTeaser straight />
      <ScrollAnimation animateIn="fadeInUp" animatePreScroll={false} animateOnce>
        <Solutions />
      </ScrollAnimation>
      <ScrollAnimation animateIn="fadeInUp" animatePreScroll={false} animateOnce>
        <Services />
      </ScrollAnimation>
      <ScrollAnimation animateIn="fadeInUp" animatePreScroll={false} animateOnce>
        <Clients defaultTitle />
      </ScrollAnimation>
      <ScrollAnimation animateIn="fadeInUp" animatePreScroll={false} animateOnce>
        <Benefits />
      </ScrollAnimation>
      <ScrollAnimation animateIn="fadeInUp" animatePreScroll={false} animateOnce>
        <Reviews />
      </ScrollAnimation>
      <ScrollAnimation animateIn="fadeInUp" animatePreScroll={false} animateOnce>
        <CaseTeasers
          list={['mitsubishi', 'smm', 'dertouristik', 'plattenplaner', 'lhh', 'etravel', 'aldiana', 'weekend', 'bedfinder']}
          allButton
        />
      </ScrollAnimation>
      <Scrollindicator />
      <ScrollAnimation animateIn="fadeInUp" animatePreScroll={false} animateOnce>
        <Products type="quickstart" />
      </ScrollAnimation>
      <ScrollAnimation animateIn="fadeInUp" animatePreScroll={false} animateOnce>
        <ProductTeaser />
      </ScrollAnimation>
    </div>
  )
}

export default applyStylingHoc(baseStyles)<$Diff<Props, StyleProps> & StyleOuterProps, Function>(HomePage)
